import * as React from 'react';
import Img from 'gatsby-image';

import Card from './Card';
import Link from './Link';

const BlogCard = ({ post, fluidImage }) => {
  const to = `/${post.fields.slug}`;
  return (
    <Card key={post.id}>
      {fluidImage && post.frontmatter.banner && (
        <div className="mb-8 rounded overflow-hidden">
          <Link aria-label={`View ${post.frontmatter.title} article`} to={to}>
            <Img sizes={post.frontmatter.banner.childImageSharp.fluid} />
          </Link>
        </div>
      )}
      <h2 className="font-bold text-2xl leading-none mb-6">
        <Link to={to} aria-label={`View ${post.frontmatter.title}`}>
          {post.frontmatter.title}
        </Link>
      </h2>
      <div className="mb-6">{post.excerpt}</div>
      <div className="flex justify-between">
        <Link to={to} aria-label={`View ${post.frontmatter.title}`}>
          Read Article →
        </Link>
        <div className="text-gray-500">{post.frontmatter.date}</div>
      </div>
    </Card>
  );
};

export default BlogCard;
