import * as React from 'react';

const Card = ({ children }) => {
  return (
    <div className="bg-white p-4 sm:p-8 shadow sm:rounded-lg mb-4">
      {children}
    </div>
  );
};

export default Card;
