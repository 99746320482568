import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import Link from 'components/Link';
import Container from '../components/Container';
import BlogCard from '../components/BlogCard';
import Seo from '../components/seo/Seo';

const Hero = () => {
  return (
    <section className="bg-bm-blue sm:p-24 sm:pb-32 pt-20 pb-28 px-8 text-white">
      <Container>
        <h1 className="sm:text-6xl text-4xl font-black leading-none text-center">
          Learn how to make things.
        </h1>
        <div />
      </Container>
    </section>
  );
};

export default function Index({ data: { site, allMdx } }) {
  return (
    <Layout site={site} darkNav>
      <Seo />
      <Hero />
      <div className="bg-gray-100 sm:px-8 py-8 flex-grow">
        <Container className="-mt-16">
          {allMdx.edges.map(({ node: post }) => (
            <BlogCard key={post.id} post={post} />
          ))}
          <div className="mb-8 mt-16 text-center">
            <Link to="/blog" aria-label="Visit blog page">
              View all articles
            </Link>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            slug
            keywords
          }
        }
      }
    }
  }
`;
